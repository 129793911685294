import * as React from "react"
import { BookOpenIcon, ViewListIcon } from "@heroicons/react/outline"
import EmailForm from "./EmailForm"
import Seo from "../seo"

export default function ComingSoon() {
  return (
    <>
      <Seo title="Coming Soon" />
      <EmailForm />
    </>
  )
}
