import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Faq from "../components/Faq/Faq"
import ComingSoon from "../components/ComingSoon/ComingSoon"

const FaqPage = () => (
  <Layout>
    <ComingSoon />
  </Layout>
)

export default FaqPage
